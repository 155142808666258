import React from 'react';
import { Helmet } from 'rnd-helmet';
import { injectIntl } from 'react-intl';
import HeaderText from '@UI/HeaderText';
import BlogFilter from '@Filters/Blog';
import SortBar from '@Components/SortBar';
import Pager from '@Components/Pager';
import Breadcrumbs from '@Components/Breadcrumbs';
import NoResults from '@Components/NoResults';
import Block from '@UI/Block';
import Optimizely from '@TrackingComponents/Optimizely';
import { useRouteMatch } from 'react-router-dom';
import GetRouteFromUrl from 'GetRouteFromUrl';
import ArticleList from '@UI/ArticleOverview/List';
import handleFirstPageRedirect from 'handleFirstPageRedirect';
import handleUnknownParams from 'handleUnknownParams';
import handleStaticContext from 'handleStaticContext';
import getCanonicalLink from 'getCanonicalLink';
import appendPageToMetaTitle from 'appendPageToMetaTitle';
import capitalizeFirstLetter from 'capitalizeFirstLetter';
import updatePressSpecificMeta from 'updatePressSpecificMeta';
import withSearch from '../../components/hoc/Search';

function Press({
  intl, searchParams, pager, articles, total, canonicalOverrides, staticContext,
}) {
  const { locale } = intl;
  const route = useRouteMatch();
  const routeConfig = GetRouteFromUrl();
  const baseUrl = route.path.replace(':searchParams*', '');
  const pageType = 'press';

  if (searchParams && searchParams.page && parseInt(searchParams.page) === 1) {
    return handleFirstPageRedirect(baseUrl, searchParams, pageType);
  }

  if (searchParams && searchParams.unknown) {
    return handleUnknownParams();
  }

  handleStaticContext(staticContext, locale);

  const {
    metaTitle,
    metaDescription,
    titleTop,
    titleBottom,
  } = updatePressSpecificMeta(intl, searchParams);

  const formattedMetaTitle = appendPageToMetaTitle(capitalizeFirstLetter(metaTitle), intl, searchParams.page);
  const canonicalLink = getCanonicalLink(baseUrl, searchParams, canonicalOverrides, pageType);

  return (
    <div id="articles-search-listing">
      <Optimizely />
      <Helmet>
        <title>{formattedMetaTitle}</title>
        {metaDescription ? <meta name="description" content={metaDescription} /> : null}
        <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}${canonicalLink}`} />
      </Helmet>
      <Breadcrumbs breadcrumbs={routeConfig.breadcrumbs} currentRoute={route} searchParams={searchParams} pageType="press" />
      <HeaderText
        bgColor="blue"
        titleTop={titleTop}
        titleBottom={titleBottom}
      >
        {intl.messages['PressHeader.text'] && intl.formatMessage({ id: 'PressHeader.text' })}
      </HeaderText>
      <SortBar resultsTotal={total} searchParams={searchParams} />
      <Block beforeContent={<BlogFilter pageType="press" searchParams={searchParams} resultsTotal={total} />} align="right" contentSize="s" type="filter">
        <ArticleList>
          {total > 0 ? articles : <NoResults />}
        </ArticleList>
        <Pager previous={pager.previous} pagesList={pager.pages} next={pager.next} />
      </Block>
    </div>
  );
}

export default injectIntl(withSearch(Press));
